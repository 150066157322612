<template>
  <div
    :class="`${newsGroupOpened(item.id) ? 'bg-gray-800/50' : ''}`"
    class="border-b border-gray-700 border-opacity-25"
  >
    <div v-if="item.hasCheckpoint" class="mb-8 mt-3 flex w-full items-center border-b-2 border-blue-600">
      <div class="mb-3 ml-auto mr-auto rounded-full bg-blue-600 px-3 py-1 text-xs">
        Left off at {{ minimalTimeWithinToday(item.checkpoint) }}
      </div>
    </div>
    <div
      class="relative flex"
      @mouseover="mouseEnter"
      @mouseleave="mouseLeave"
      :class="{ 'border-l-2 border-blue-400 bg-blue-900/[0.15]': latestNewsItem }"
    >
      <div class="flex w-20 flex-col items-end justify-center px-2">
        <transition name="fade" leave-active-class="hidden">
          <TableCellPartNewsActions
            v-if="hovering"
            class="mb-1"
            :is-starred="starred"
            :can-star="item.starredInfo.canChange"
            :can-delete="canEditOrDelete"
            :can-edit="canEditOrDelete"
            :can-global-star="canEditOrDelete"
            @toggle-starred="toggleStarred"
            @delete="adminDelete"
            @edit="$eventHub.$emit('showEditTagsModal', item)"
            @share="share"
            @global-star="toggleGlobalStar"
          />
        </transition>
        <span :class="`flex items-center text-right text-xs text-secondary `">
          {{ minimalTimeWithinToday(item.timestamp) }}
        </span>
      </div>
      <div class="flex-1 px-4 py-2">
        <div class="flex flex-wrap text-xs text-gray-300">
          <span
            :class="`flex select-none items-center space-x-1 pr-2 ${holdingShift ? 'cursor-pointer text-red-400' : ''}`"
            @click="handleSourceClick"
            @mouseover="hoveringOverSource = true"
            @mouseleave="hoveringOverSource = false"
          >
            <div v-if="holdingShift">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="-mt-0.5 inline h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <template v-if="item.sources.length > 1">
              <BaseTooltip v-if="customNavigation" class="flex items-center">
                <template #target>
                  {{ sourceName }}
                </template>
                <template #default>
                  {{ items.sources.map(o => o.name).join(', ') }}
                </template>
              </BaseTooltip>
            </template>
            <template v-else>{{ sourceName }} </template>
          </span>
          <div class="flex w-full flex-1 py-0.5">
            <TableCellPartPinnedTag :name="starred ? item.starredInfo.name : ''" />
            <div class="ml-auto" v-if="item.price_change">
              <AgGridPercentageCell :params="{ value: item.price_change }" class="text-xs" />
            </div>
          </div>
          <TableCellPartTieStarredTag
            :name="
              item.is_starred_by_the_tie
                ? item.starred_by_the_tie_user.first_name
                  ? `${item.starred_by_the_tie_user.first_name}`
                  : item.starred_by_the_tie_user.email
                : ''
            "
          />
        </div>
        <a v-bind="{ ...(!signalNews(item) && { href: item?.link }) }" target="_blank">
          <p
            class="mt-0.5 line-clamp-2 cursor-pointer break-words"
            @click="signalNews(item) && showOnChainSidebar(item.link)"
            v-html="item.group_similar_news ? item.grouped_headline : item.translated_headline || item.headline"
          />
        </a>
        <BaseButton
          v-if="item.similarNews"
          type="secondary"
          size="xs"
          class="mt-1 rounded-full"
          @press="toggleVisibility(item.id)"
        >
          <span class="mx-2 flex items-center">
            <span class="pr-0.5">
              {{ newsGroupOpened(item.id) ? 'Hide' : 'Show' }} {{ item.grouped.length }} Related Headlines
            </span>
            <ChevronDoubleDownIcon v-if="!newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleDownIcon>
            <ChevronDoubleUpIcon v-if="newsGroupOpened(item.id)" class="h-3 w-3"></ChevronDoubleUpIcon>
          </span>
        </BaseButton>
        <div class="mt-1 flex w-full justify-between text-xs text-secondary">
          <div class="flex flex-wrap">
            <TableCellPartNewsTags :item="item" @handle-tag-click="handleTagClick" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="pl-[4.1rem]"
    v-if="newsGroupOpened(item.id)"
    :class="`${newsGroupOpened(item.id) ? 'bg-gray-800/50' : ''}`"
  >
    <CellActionNews v-for="newsItem in item.grouped" :item="newsItem" :is-focus-out="isFocusOut" :key="newsItem.id" />
  </div>
</template>

<script>
import { router } from '@inertiajs/vue3';
import { flashMessage, copyToClipboard } from '@/composeables/helpers';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/vue/20/solid';
import global from '@/mixins/global.js';
import FilterMixin from '@/mixins/filters';
import TableCellPartNewsActions from './part/TableCellPartNewsActions.vue';
import TableCellPartPinnedTag from './part/TableCellPartPinnedTag.vue';
import TableCellPartTieStarredTag from './part/TableCellPartTieStarredTag.vue';
import TableCellPartNewsTags from './part/TableCellPartNewsTags.vue';
import { signalNews } from '@/composeables/news';
import { showOnChainSidebar } from '@/composeables/news';
import { DateTime } from 'luxon';
import AgGridPercentageCell from '@/components/ag_grid/AgGridPercentageCell.vue';

export default {
  name: 'CellActionNews',
  components: {
    TableCellPartNewsActions,
    TableCellPartPinnedTag,
    TableCellPartTieStarredTag,
    TableCellPartNewsTags,
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
    AgGridPercentageCell
  },
  mixins: [global, FilterMixin],
  props: {
    isFocusOut: {
      type: Boolean,
      default: () => false
    },
    item: Object,
    fromLabs: {
      type: Boolean,
      default: () => false
    },
    showGroupedNewsItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isStarred: undefined,
      hovering: false,
      hoveringOverSource: false,
      holdingShift: false,
      interval: 0
    };
  },
  mounted() {
    document.addEventListener('keydown', this.isHoldingShift);
    document.addEventListener('keyup', this.isHoldingShift);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.isHoldingShift);
    document.removeEventListener('keyup', this.isHoldingShift);
  },
  computed: {
    canEditOrDelete() {
      return this.isTheTieTeamMember && !this.fromLabs;
    },
    latestNewsItem() {
      const timestamp = DateTime.fromISO(this.item.timestamp);
      const now = DateTime.now();
      const minutes = now.diff(timestamp, 'minutes').minutes;
      return minutes < 5 ? true : false;
    },
    companyTagGroups() {
      return this.$store.state.companyTagGroups;
    },
    sourceName() {
      return this.item.sources.length > 1
        ? `${this.item.sources[0]?.name} and ${this.item.sources.length - 1} more...`
        : this.item.sources[0]?.name;
    },
    sortedTags: function () {
      const sigdev = this.item.tags.filter(i => i.type === 'sigdev');
      const coins = this.item.tags.filter(i => i.type === 'coin');

      return sigdev.concat(coins);
    },
    starred: function () {
      if (this.isStarred === undefined) {
        return this.item.starredInfo?.isStarred;
      }

      return this.isStarred;
    }
  },
  watch: {
    item() {
      this.isStarred = undefined;
    },
    isFocusOut() {
      // In order to remove shift exclude option on page focus out/ page hide
      if (this.holdingShift) {
        this.holdingShift = !this.holdingShift;
      }
    }
  },
  methods: {
    showOnChainSidebar,
    signalNews,
    handleTagClick: function (tag, newsItem) {
      if (tag.tag_subgroup.name == 'Coins' && tag.coin) {
        this.$store.dispatch('enableCoinSidebar', { coin: tag.coin.uid, newsItem: newsItem });
      }

      if (this.companyTagGroups.includes(tag.tag_subgroup.name)) {
        const company = this.$store.state.companySlugs.find(x => x.tag == tag.id);
        if (company) {
          router.visit(`/company/${company.company_uid}`);
        }
      }
    },
    handleSourceClick(e) {
      if (e.shiftKey) {
        this.$eventHub.$emit('excludeSource', {
          id: this.item.sources[0].id
        });
      }
    },
    isHoldingShift(e) {
      if (!this.item.sourceShift) {
        return;
      }

      if (e.srcElement.tagName.toLowerCase() === 'input' && e.shiftKey) {
        return;
      }
      this.holdingShift = e.shiftKey;
    },
    toggleGlobalStar() {
      this.$http.patch(`/admin/news_items/${this.item.id}`, {
        is_starred: !this.item.is_starred_by_the_tie,
        news_item_id: this.item.id
      });
    },
    toggleStarred() {
      if (!this.item.starredInfo.canChange) {
        return;
      }

      this.isStarred = !this.starred;
      this.$http.patch('/data/team_news_items/0', {
        is_starred_by_my_team: this.isStarred,
        news_item_id: this.item.id
      });
    },
    adminDelete() {
      if (!confirm('Are you sure you want to hide this story for every SigDev user?')) {
        return;
      }

      this.$http.patch(`/admin/news_items/${this.item.id}`, {
        is_hidden: true,
        news_item_id: this.item.id
      });

      flashMessage({
        type: 'success',
        message: 'News item deleted successfully.'
      });
    },
    share() {
      const link = this.item.link;
      copyToClipboard(link, 'Copied share link to clipboard!');
    },
    mouseEnter() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.hovering = true;
      }, 500);
    },
    mouseLeave() {
      clearInterval(this.interval);
      this.hovering = false;
    },
    toggleVisibility(id) {
      this.$eventHub.$emit('toggle:grouped-preview-items', id);
    },
    newsGroupOpened(id) {
      return this.showGroupedNewsItems.includes(id);
    }
  }
};
</script>
